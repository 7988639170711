import Vue from 'vue'
//引入外部js




import './hs.megamenu.js' //导航下拉js
// import './main.bundle.js'
// import './rocket-loader.min.js'

// touchvue.js


$(window).on('load', function () {
    // 初始化HSMegaMenu组件
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991,
        hideTimeOut: 0
    });
});

function vueTouch(el, type, binding) {
    this.el = el;
    this.type = type;
    this.binding = binding;
    //直接调用
    var hammertime = new Hammer(this.el);
    hammertime.on(this.type, this.binding.value);
};

// 初始化滚动导航组件
let timer = setTimeout(function () {
    var $head = $('#ha-header');
    $('.ha-waypoint').each(function (i) {
        var $el = $(this),
            animClassDown = $el.data('animateDown'),
            animClassUp = $el.data('animateUp');

        $el.waypoint(function (direction) {
            if (direction === 'down' && animClassDown) {
                $head.attr('class', 'ha-header ' + animClassDown);
            }
            else if (direction === 'up' && animClassUp) {
                $head.attr('class', 'ha-header ' + animClassUp);
            }
        }, { offset: '100%' });
    });
    clearTimeout(timer)
}, 100);


