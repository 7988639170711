<template>
  <div class="headerNav">
    <div id="ha-header" class="ha-header ha-header-large z-index-100  ">
      <div class="ha-header-perspective">
        <div class="ha-header-front">
          <header id="header"
            class="u-header u-header--modern u-header--bordered u-header--bg-transparent u-header--white-nav-links u-header--sticky-top-lg">
            <div class="u-header__section">
              <div id="logoAndNav" class="container">
                <nav class="js-mega-menu navbar navbar-expand-lg u-header__navbar">
                  <!-- LOGO -->
                  <div class="u-header__navbar-brand-wrapper position-absolute top left w-100 h-100 z-index-0">
                    <router-link :to="'/'">
                      <h1 class="w-100 logo h-100"></h1>
                    </router-link>
                  </div>
                  <!-- LOGO - 结束 -->
                  <!-- 响应切换按钮 -->
                  <button type="button"
                    class="navbar-toggler btn u-hamburger u-header__hamburger position-absolute right top pt-4 pt-sm-3"
                    aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar" data-toggle="collapse"
                    data-target="#navBar">
                    <span class="d-none d-sm-inline-block text-secondary font-weight-bold h6 pt-2">导航</span>
                    <span id="hamburgerTrigger" class="u-hamburger__box ml-3">
                      <span class="u-hamburger__inner"></span>
                    </span>
                  </button>
                  <!-- 响应切换按钮 - 结束 -->
                  <!-- 导航 -->
                  <div id="navBar" class="collapse navbar-collapse u-header__navbar-collapse py-0 p-sm-0 p-3">
                    <ul class="navbar-nav u-header__navbar-nav pt-5 pt-sm-0 mt-4 mt-sm-0 px-4 px-sm-0">


                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'/'">
                          <a @click="clickCloseNav" id="homeMegaMenu"
                            class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            首页
                          </a>
                        </router-link>
                      </li>

                      <!-- <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'i3ku'">
                          <a @click="clickCloseNav" id="solutionMenu"
                            class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            卓越研究院
                          </a>
                        </router-link>
                      </li> -->

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <router-link :to="'coachPage'">
                          <a id="coachMegaMenu" class="nav-link u-header__nav-link font-weight-bold" href="javascript:;"
                            aria-haspopup="true" aria-expanded="false" aria-labelledby="coachSubMenu">
                            教练引领式行动学习
                            <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                          </a>
                        </router-link>
                        <ul id="coachMegaSub" class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                          style="min-width: 220px;" aria-labelledby="coachMegaSub">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/coachPage">教练引领式行动学习价值与选题</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/coachPage01">教练引领式行动学习项目介绍</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/coachPage02">教练引领式行动学习项目如何助力领导力提升？</a>
                          </li> 
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/coachPage03">高管如何突破“防御性归因”思维，促进组织学习？</a>
                          </li> 
                        </ul>
                      </li>


                      <!-- <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'news'">
                          <a @click="clickCloseNav" id="solutionMenu"
                            class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            新闻资讯
                          </a>
                        </router-link>
                      </li> -->

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'catalysts'">
                          <a @click="clickCloseNav" id="solutionMenu"
                            class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            催化师认证
                          </a>
                        </router-link>
                      </li>

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <router-link :to="'solution'">
                          <a id="homeMegaMenu" class="nav-link u-header__nav-link font-weight-bold" href="javascript:;"
                            aria-haspopup="true" aria-expanded="false" aria-labelledby="teachingProjectSubMenu">
                            解决方案
                            <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                          </a>
                        </router-link>
                        <ul id="homeMegaSub" class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                          style="min-width: 220px;" aria-labelledby="homeMegaSub">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution01">战略落地</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution02">领导力提升</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution03">行动学习</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution04">海外业务拓展</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution05">销售能力提升</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/solution06">培训系列服务</a>
                          </li>

                        </ul>
                      </li>

 

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <router-link :to="'course'">
                          <a id="curriculumStructureMenu" class="nav-link u-header__nav-link font-weight-bold"
                            href="javascript:;" aria-haspopup="true" aria-expanded="false"
                            aria-labelledby="blogSubMenu">
                            课程体系
                            <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                          </a></router-link>
                        <ul id="curriculumStructureSubMenu" class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                          style="min-width: 220px;" aria-labelledby="curriculumStructureSubMenu">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/strategy">战略与绩效</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/leadership">领导力系列</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/businessExpansion">海外业务拓展</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/ability">能力提升</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/train">培训系列</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/individualAbility">个人效能提升</a>
                          </li>
                        </ul>
                      </li>

                      <!-- <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4">
                        <router-link :to="'microLesson'">
                          <a @click="clickCloseNav" id="homeMegaMenu"
                            class="nav-link u-header__nav-link font-weight-bold" href=" ">
                            卓越微课堂
                          </a>
                        </router-link>
                      </li> -->

                      <!-- <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <router-link :to="'microLesson'"> 
                        <a id="talentDevelopmentMenu" class="nav-link u-header__nav-link font-weight-bold" href="javascript:;"
                          aria-haspopup="true" aria-expanded="false" aria-labelledby="shopSubMenu">
                          卓越微课堂
                          <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                        </a>
                      </router-link>
 
                        <ul id="talentDevelopmentpSubMenu" class="list-inline hs-sub-menu u-header__sub-menu mb-0" style="min-width: 220px;"
                          aria-labelledby="talentDevelopmentMegaMenu">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/microLesson">问题分析与解决</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a class="nav-link u-header__sub-menu-nav-link" href="#/microLesson01">卓越3分钟</a>
                          </li> 
                        </ul>
                      </li> -->

                      <li class="nav-item hs-has-sub-menu u-header__nav-item pb-0 pb-sm-4" data-event="hover"
                        data-animation-in="fadeInUp" data-animation-out="fadeOut">
                        <router-link :to="'about'">
                          <a id="aboutUsMenu" class="nav-link u-header__nav-link font-weight-bold" href="javascript:;"
                            aria-haspopup="true" aria-expanded="false" aria-labelledby="shopSubMenu">
                            关于我们
                            <span class="fa fa-angle-down u-header__nav-link-icon"></span>
                          </a>
                        </router-link>
                        <!-- Shop - Submenu -->
                        <ul id="aboutUsSubMenu" class="list-inline hs-sub-menu u-header__sub-menu mb-0"
                          style="min-width: 220px;" aria-labelledby="aboutUsMegaMenu">
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/about">企业介绍</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/consultant">顾问团队</a>
                          </li>
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/news">新闻资讯</a>
                          </li>
                          <!-- <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/contactUs">联系我们</a>
                          </li> -->
                          <li class="dropdown-item u-header__sub-menu-list-item py-0">
                            <a @click="clickCloseNav" class="nav-link u-header__sub-menu-nav-link"
                              href="#/joinUs">加入我们</a>
                          </li>
                        </ul>
                      </li>


                      <!-- End Shop -->
                    </ul>
                  </div>
                  <!-- 导航 - 结束 -->
                </nav>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>

    <!--当屏幕滚动到该位置时导航透明-->
    <div class="bg-danger ha-waypoint position-absolute z-index-0" style=" min-height:55rem"
      data-animate-down="ha-header-color"></div>

    <!--当屏幕滚动到该位置时导航背景白色-->
    <div class="bg-danger ha-waypoint position-absolute z-index-0" data-animate-down="ha-header-show"
      data-animate-up="ha-header-color" style="top:55rem"></div>

  </div>
</template>
<script>
  export default {
    name: "headerNav",
    methods: {
      clickCloseNav() {
        $('.navbar-collapse').collapse('hide');
      }
    }
  };
</script>

<style scoped>
</style>