//import Vue from 'vue'
import Router from 'vue-router'
// import Index from '@/views/index'
// import About from '@/views/about'
// Vue.use(VueRouter)

export default new Router({
  routes: [{
    path: '/',
    name: 'index',
    component: resolve => require(['@/views/index'], resolve),
    meta: {
      title: '卓越体验官方网站 - 首页'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: resolve => require(['@/views/about'], resolve),
    meta: {
      title: '卓越体验-企业介绍'
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: resolve => require(['@/views/solution'], resolve),
    meta: {
      title: '卓越体验解决方案'
    }
  },
  {
    path: '/i3ku',
    name: 'i3ku',
    component: resolve => require(['@/views/i3ku'], resolve),
    meta: {
      title: '卓越体验-I3KU模型'
    }
  },
  {
    path: '/talentedPerson',
    name: 'talentedPerson',
    component: resolve => require(['@/views/talentedPerson'], resolve),
    meta: {
      title: '卓越体验-人才理念'
    }
  },
  {
    path: '/cuttingEdge',
    name: 'cuttingEdge',
    component: resolve => require(['@/views/cuttingEdge'], resolve),
    meta: {
      title: '卓越体验-前沿洞察'
    }
  },
  {
    path: '/officialAnnouncement',
    name: 'officialAnnouncement',
    component: resolve => require(['@/views/officialAnnouncement'], resolve),
    meta: {
      title: '卓越体验-卓越观点'
    }
  },
  {
    path: '/consultant',
    name: 'consultant',
    component: resolve => require(['@/views/consultant'], resolve),
    meta: {
      title: '卓越体验-顾问团队'
    }
  },
  {
    path: '/ggw',
    name: 'ggw',
    component: resolve => require(['@/views/zygw/ggw'], resolve),
    meta: {
      title: '卓越体验总经理首席学习顾问郭广文'
    }
  },
  {
    path: '/gwzhm',
    name: 'gwzhm',
    component: resolve => require(['@/views/zygw/gwzhm'], resolve),
    meta: {
      title: '卓越体验顾问讲师赵浩明'
    }
  },
  {
    path: '/gwdm',
    name: 'gwdm',
    component: resolve => require(['@/views/zygw/gwdm'], resolve),
    meta: {
      title: '卓越体验资深顾问戴民'
    }
  },
  {
    path: '/gwhyl',
    name: 'gwhyl',
    component: resolve => require(['@/views/zygw/gwhyl'], resolve),
    meta: {
      title: '卓越体验项目总监、顾问黄永玲'
    }
  },
  {
    path: '/gwlzh',
    name: 'gwlzh',
    component: resolve => require(['@/views/zygw/gwlzh'], resolve),
    meta: {
      title: '卓越体验WIAC《行动教练》课程认证讲师李志红'
    }
  },
  {
    path: '/gwlys',
    name: 'gwlys',
    component: resolve => require(['@/views/zygw/gwlys'], resolve),
    meta: {
      title: '卓越体验顾问吕玉淑'
    }
  },
  {
    path: '/gwsh',
    name: 'gwsh',
    component: resolve => require(['@/views/zygw/gwsh'], resolve),
    meta: {
      title: '卓越体验资深顾问孙宏'
    }
  },
  {
    path: '/gwtzy',
    name: 'gwtzy',
    component: resolve => require(['@/views/zygw/gwtzy'], resolve),
    meta: {
      title: '卓越体验际版权课程认证讲师唐竹芸'
    }
  },
  {
    path: '/gwcp',
    name: 'gwcp',
    component: resolve => require(['@/views/zygw/gwcp'], resolve),
    meta: {
      title: '卓越体验顾问陈萍'
    }
  },
  {
    path: '/gwwnh',
    name: 'gwwnh',
    component: resolve => require(['@/views/zygw/gwwnh'], resolve),
    meta: {
      title: '卓越体验资深顾问汪宁红'
    }
  },
  {
    path: '/gwljs',
    name: 'gwljs',
    component: resolve => require(['@/views/zygw/gwljs'], resolve),
    meta: {
      title: '人才发展协会（ATD）培训师证书课程授权讲师林佳澍'
    }
  },
  {
    path: '/gwgzm',
    name: 'gwgzm',
    component: resolve => require(['@/views/zygw/gwgzm'], resolve),
    meta: {
      title: '卓越体验管理顾问公司 资深学习顾问郭志梅'
    }
  },
  {
    path: '/gwzyq',
    name: 'gwzyq',
    component: resolve => require(['@/views/zygw/gwzyq'], resolve),
    meta: {
      title: ' 中国惠普培训业务高级顾问张益清'
    }
  },
  {
    path: '/gwzq',
    name: 'gwzq',
    component: resolve => require(['@/views/zygw/gwzq'], resolve),
    meta: {
      title: ' 专业咨询部咨询顾问周勤'
    }
  },
  {
    path: '/gwlq',
    name: 'gwlq',
    component: resolve => require(['@/views/zygw/gwlq'], resolve),
    meta: {
      title: ' 专业培训顾问雷琼'
    }
  },
  {
    path: '/gwwmh',
    name: 'gwwmh',
    component: resolve => require(['@/views/zygw/gwwmh'], resolve),
    meta: {
      title: ' 复旦大学MBA，中国首批“Lego Serious Play”认证教练，CIPT 认证培训师吴敏华'
    }
  },
  {
    path: '/gwyht',
    name: 'gwyht',
    component: resolve => require(['@/views/zygw/gwyht'], resolve),
    meta: {
      title: ' 惠普商学院授权讲师艺海彤'
    }
  },
  {
    path: '/atd',
    name: 'atd',
    component: resolve => require(['@/views/specialTopic/atd'], resolve),
    meta: {
      title: ' ATD2019中国峰会-提升人才发展的战略伙伴地位'
    }
  },
  {
    path: '/joinUs',
    name: 'joinUs',
    component: resolve => require(['@/views/joinUs'], resolve),
    meta: {
      title: '加入卓越体验'
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: resolve => require(['@/views/contactUs'], resolve),
    meta: {
      title: '卓越体验联系我们'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: resolve => require(['@/views/news'], resolve),
    meta: {
      title: '卓越体验新闻资讯'
    }
  },
  {
    path: '/news001',
    name: 'news001',
    component: resolve => require(['@/views/news/news001'], resolve),
    meta: {
      title: '一个“内在俱足”的小孩就在你我心中'
    }
  },
  {
    path: '/news002',
    name: 'news002',
    component: resolve => require(['@/views/news/news002'], resolve),
    meta: {
      title: '沙龙报道 |《企业经营目标达成的关键因素》'
    }
  },
  {
    path: '/news003',
    name: 'news003',
    component: resolve => require(['@/views/news/news003'], resolve),
    meta: {
      title: '从随意到刻意——翻转人生的终极密码！'
    }
  },
  {
    path: '/news004',
    name: 'news004',
    component: resolve => require(['@/views/news/news004'], resolve),
    meta: {
      title: '聚焦能量，引爆人生'
    }
  },
  {
    path: '/news005',
    name: 'news005',
    component: resolve => require(['@/views/news/news005'], resolve),
    meta: {
      title: '金融在线领导力训练营《引力波发现之旅》'
    }
  },
  {
    path: '/news006',
    name: 'news006',
    component: resolve => require(['@/views/news/news006'], resolve),
    meta: {
      title: '沙龙邀请函 | 《实现年度经营目标的关键因素》'
    }
  },
  {
    path: '/news007',
    name: 'news007',
    component: resolve => require(['@/views/news/news007'], resolve),
    meta: {
      title: '缺乏自我认知，再多思考都是枉费！'
    }
  },
  {
    path: '/news008',
    name: 'news008',
    component: resolve => require(['@/views/news/news008'], resolve),
    meta: {
      title: '卓越体验携手Action Learning Associates 开启行动学习新篇章'
    }
  },
  {
    path: '/strategy',
    name: 'strategy',
    component: resolve => require(['@/views/course/strategy'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-塑造企业文化'
    }
  },
  {
    path: '/strategy01',
    name: 'strategy01',
    component: resolve => require(['@/views/course/strategy01'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-乐高认真玩——领导力自我探索之旅'
    }
  },
  {
    path: '/strategy02',
    name: 'strategy02',
    component: resolve => require(['@/views/course/strategy02'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-创新思考工作坊'
    }
  },
  {
    path: '/strategy03',
    name: 'strategy03',
    component: resolve => require(['@/views/course/strategy03'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-商业模式画布工作坊'
    }
  },
  {
    path: '/strategy04',
    name: 'strategy04',
    component: resolve => require(['@/views/course/strategy04'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-战略落地工作坊'
    }
  },
  {
    path: '/strategy05',
    name: 'strategy05',
    component: resolve => require(['@/views/course/strategy05'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-全面经营训练'
    }
  },
  {
    path: '/strategy06',
    name: 'strategy06',
    component: resolve => require(['@/views/course/strategy06'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-绩效管理'
    }
  },
  {
    path: '/strategy07',
    name: 'strategy07',
    component: resolve => require(['@/views/course/strategy07'], resolve),
    meta: {
      title: '卓越体验-课程体系-战略与绩效-非财务经理的财务管理'
    }
  },
  {
    path: '/leadership',
    name: 'leadership',
    component: resolve => require(['@/views/course/leadership'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-领越TM领导力'
    }
  },
  {
    path: '/leadership00',
    name: 'leadership00',
    component: resolve => require(['@/views/course/leadership00'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-沙克尔顿领导力'
    }
  },
  {
    path: '/leadership01',
    name: 'leadership01',
    component: resolve => require(['@/views/course/leadership01'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-巅峰领导力'
    }
  },
  {
    path: '/leadership02',
    name: 'leadership02',
    component: resolve => require(['@/views/course/leadership02'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-4D打造高绩效的领导者和团队'
    }
  },
  {
    path: '/leadership03',
    name: 'leadership03',
    component: resolve => require(['@/views/course/leadership03'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-适应性领导力'
    }
  },
  {
    path: '/leadership04',
    name: 'leadership04',
    component: resolve => require(['@/views/course/leadership04'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-引领卓越团队'
    }
  },
  {
    path: '/leadership05',
    name: 'leadership05',
    component: resolve => require(['@/views/course/leadership05'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-从优秀专家到卓越管理者'
    }
  },
  {
    path: '/leadership06',
    name: 'leadership06',
    component: resolve => require(['@/views/course/leadership06'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-团队共创引导'
    }
  },
  {
    path: '/leadership07',
    name: 'leadership07',
    component: resolve => require(['@/views/course/leadership07'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-教练式领导'
    }
  },
  {
    path: '/leadership08',
    name: 'leadership08',
    component: resolve => require(['@/views/course/leadership08'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-非现金激励与辅导'
    }
  },
  {
    path: '/leadership09',
    name: 'leadership09',
    component: resolve => require(['@/views/course/leadership09'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-业务流程管理工作坊'
    }
  },
  {
    path: '/leadership10',
    name: 'leadership10',
    component: resolve => require(['@/views/course/leadership10'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-员工的日常辅导'
    }
  },
  {
    path: '/leadership11',
    name: 'leadership11',
    component: resolve => require(['@/views/course/leadership11'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-追求卓越'
    }
  },
  {
    path: '/leadership12',
    name: 'leadership12',
    component: resolve => require(['@/views/course/leadership12'], resolve),
    meta: {
      title: '卓越体验-课程体系-领导力系列-激发天赋能量'
    }
  },
  {
    path: '/businessExpansion',
    name: 'businessExpansion',
    component: resolve => require(['@/views/course/businessExpansion'], resolve),
    meta: {
      title: '卓越体验-课程体系-海外业务拓展-海外业务规划'
    }
  },
  {
    path: '/businessExpansion01',
    name: 'businessExpansion01',
    component: resolve => require(['@/views/course/businessExpansion01'], resolve),
    meta: {
      title: '卓越体验-课程体系-海外业务拓展-海外业务开拓'
    }
  },
  {
    path: '/businessExpansion02',
    name: 'businessExpansion02',
    component: resolve => require(['@/views/course/businessExpansion02'], resolve),
    meta: {
      title: '卓越体验-课程体系-海外业务拓展-跨文化交流'
    }
  },
  {
    path: '/ability',
    name: 'ability',
    component: resolve => require(['@/views/course/ability'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-营销管理五步法'
    }
  },
  {
    path: '/ability01',
    name: 'ability01',
    component: resolve => require(['@/views/course/ability01'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-顾问式销售训练营'
    }
  },
  {
    path: '/ability02',
    name: 'ability02',
    component: resolve => require(['@/views/course/ability02'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-客户拜访技巧'
    }
  },
  {
    path: '/ability03',
    name: 'ability03',
    component: resolve => require(['@/views/course/ability03'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-大客户顾问式营销训练营'
    }
  },
  {
    path: '/ability04',
    name: 'ability04',
    component: resolve => require(['@/views/course/ability04'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-专业销售谈判'
    }
  },
  {
    path: '/ability05',
    name: 'ability05',
    component: resolve => require(['@/views/course/ability05'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-项目管理实战技能'
    }
  },
  {
    path: '/ability06',
    name: 'ability06',
    component: resolve => require(['@/views/course/ability06'], resolve),
    meta: {
      title: '卓越体验-课程体系-能力提升-项目管理实战'
    }
  },
  {
    path: '/train',
    name: 'train',
    component: resolve => require(['@/views/course/train'], resolve),
    meta: {
      title: '卓越体验-课程体系-培训系列-基础培训师培训'
    }
  },
  {
    path: '/train01',
    name: 'train01',
    component: resolve => require(['@/views/course/train01'], resolve),
    meta: {
      title: '卓越体验-课程体系-培训系列-高级培训师培训'
    }
  },
  {
    path: '/train02',
    name: 'train02',
    component: resolve => require(['@/views/course/train02'], resolve),
    meta: {
      title: '卓越体验-课程体系-培训系列-课程设计与开发'
    }
  },
  {
    path: '/train03',
    name: 'train03',
    component: resolve => require(['@/views/course/train03'], resolve),
    meta: {
      title: '卓越体验-课程体系-培训系列-课程的体验化设计与引导技巧'
    }
  },
  {
    path: '/individualAbility',
    name: 'individualAbility',
    component: resolve => require(['@/views/course/individualAbility'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-时间管理'
    }
  },
  {
    path: '/individualAbility01',
    name: 'individualAbility01',
    component: resolve => require(['@/views/course/individualAbility01'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-情商管理'
    }
  },
  {
    path: '/individualAbility02',
    name: 'individualAbility02',
    component: resolve => require(['@/views/course/individualAbility02'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-情绪与压力管理'
    }
  },
  {
    path: '/individualAbility03',
    name: 'individualAbility03',
    component: resolve => require(['@/views/course/individualAbility03'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-高效沟通技巧'
    }
  },
  {
    path: '/individualAbility04',
    name: 'individualAbility04',
    component: resolve => require(['@/views/course/individualAbility04'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-系统问题分析与解决'
    }
  },
  {
    path: '/individualAbility05',
    name: 'individualAbility05',
    component: resolve => require(['@/views/course/individualAbility05'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-完美呈现展示技巧'
    }
  },
  {
    path: '/individualAbility06',
    name: 'individualAbility06',
    component: resolve => require(['@/views/course/individualAbility06'], resolve),
    meta: {
      title: '卓越体验-课程体系-个人效能提升-员工职业化训练'
    }
  },
  {
    path: '/microLesson',
    name: 'microLesson',
    component: resolve => require(['@/views/microLesson'], resolve),
    meta: {
      title: '卓越微课堂-问题分析与解决'
    }
  },
  {
    path: '/microLesson01',
    name: 'microLesson01',
    component: resolve => require(['@/views/microLesson01'], resolve),
    meta: {
      title: '卓越微课堂-卓越3分钟'
    }
  },
  {
    path: '/course',
    name: 'course',
    component: resolve => require(['@/views/course'], resolve),
    meta: {
      title: '卓越课程体系'
    }
  },
  {
    path: '/project01',
    name: 'project01',
    component: resolve => require(['@/views/cases/project01'], resolve),
    meta: {
      title: '卓越项目案例-H公司销售经理领导力提升飞鹰训练营'
    }
  },
  {
    path: '/project02',
    name: 'project02',
    component: resolve => require(['@/views/cases/project02'], resolve),
    meta: {
      title: '卓越项目案例-HPE乐高认真玩'
    }
  },
  {
    path: '/project03',
    name: 'project03',
    component: resolve => require(['@/views/cases/project03'], resolve),
    meta: {
      title: '卓越项目案例-联想服务部工程师销售能力提升'
    }
  },
  {
    path: '/projectMedical01',
    name: 'projectMedical01',
    component: resolve => require(['@/views/cases/projectMedical01'], resolve),
    meta: {
      title: '卓越项目案例-北京大学国际医院'
    }
  },
  {
    path: '/projectMedical02',
    name: 'projectMedical02',
    component: resolve => require(['@/views/cases/projectMedical02'], resolve),
    meta: {
      title: '卓越项目案例-北京儿童医院苗圃计划'
    }
  },
  {
    path: '/projectMedical03',
    name: 'projectMedical03',
    component: resolve => require(['@/views/cases/projectMedical03'], resolve),
    meta: {
      title: '卓越项目案例-东芝医疗中期战略规划营'
    }
  },
  // {
  //   path: '/projectWeb01',
  //   name: 'projectWeb01',
  //   component: resolve => require(['@/views/cases/projectWeb01'], resolve),
  //   meta: {
  //     title: '卓越项目案例-东芝医疗中期战略规划营'
  //   }
  // },
  {
    path: '/projectWeb02',
    name: 'projectWeb02',
    component: resolve => require(['@/views/cases/projectWeb02'], resolve),
    meta: {
      title: '卓越项目案例-凤凰网像CEO一样思考案例'
    }
  },
  {
    path: '/projectF01',
    name: 'projectF01',
    component: resolve => require(['@/views/cases/projectF01'], resolve),
    meta: {
      title: '卓越项目案例-F大型房地产公司沃土计划案例'
    }
  },
  {
    path: '/projectF02',
    name: 'projectF02',
    component: resolve => require(['@/views/cases/projectF02'], resolve),
    meta: {
      title: '卓越项目案例-万科北京区域装修房大学'
    }
  },
  {
    path: '/projectF03',
    name: 'projectF03',
    component: resolve => require(['@/views/cases/projectF03'], resolve),
    meta: {
      title: '卓越项目案例-华美地产猛虎训练营'
    }
  },
  {
    path: '/projectEdu01',
    name: 'projectEdu01',
    component: resolve => require(['@/views/cases/projectEdu01'], resolve),
    meta: {
      title: '卓越项目案例-北邮MBA"追求卓越"'
    }
  },
  {
    path: '/projectEdu02',
    name: 'projectEdu02',
    component: resolve => require(['@/views/cases/projectEdu02'], resolve),
    meta: {
      title: '卓越项目案例-呼家楼中心小学“未来领袖的变身之旅”冬令营'
    }
  },
  {
    path: '/wei001',
    name: 'wei001',
    component: resolve => require(['@/views/weicourse/wei001'], resolve),
    meta: {
      title: '卓越微课堂-如何制定目标才能充分调动行为？'
    }
  },
  {
    path: '/wei002',
    name: 'wei002',
    component: resolve => require(['@/views/weicourse/wei002'], resolve),
    meta: {
      title: '卓越微课堂-思维导图让你有无穷大的创造力'
    }
  },
  {
    path: '/wei003',
    name: 'wei003',
    component: resolve => require(['@/views/weicourse/wei003'], resolve),
    meta: {
      title: '卓越微课堂-经常用头脑风暴的你， 要会复式投票法'
    }
  },
  {
    path: '/wei004',
    name: 'wei004',
    component: resolve => require(['@/views/weicourse/wei004'], resolve),
    meta: {
      title: '卓越微课堂-手把手教会你偏好矩阵法'
    }
  },
  {
    path: '/wei005',
    name: 'wei005',
    component: resolve => require(['@/views/weicourse/wei005'], resolve),
    meta: {
      title: '卓越3分钟丨第一集丨经理人如何积极倾听？'
    }
  },
  {
    path: '/wei006',
    name: 'wei006',
    component: resolve => require(['@/views/weicourse/wei006'], resolve),
    meta: {
      title: '卓越3分钟丨第三集丨经理人授权的流程'
    }
  },
  {
    path: '/wei007',
    name: 'wei007',
    component: resolve => require(['@/views/weicourse/wei007'], resolve),
    meta: {
      title: '卓越3分钟丨第四集丨经理人授权的误区'
    }
  },
  {
    path: '/wei008',
    name: 'wei008',
    component: resolve => require(['@/views/weicourse/wei008'], resolve),
    meta: {
      title: '卓越3分钟|第六集|经理人如何积极思维？'
    }
  },
  {
    path: '/wei009',
    name: 'wei009',
    component: resolve => require(['@/views/weicourse/wei009'], resolve),
    meta: {
      title: '卓越3分钟丨第八集丨如何下达逐客令'
    }
  },
  {
    path: '/wei010',
    name: 'wei010',
    component: resolve => require(['@/views/weicourse/wei010'], resolve),
    meta: {
      title: '卓越3分钟丨第十集丨如何进行绩效指导'
    }
  },
  {
    path: '/wei011',
    name: 'wei011',
    component: resolve => require(['@/views/weicourse/wei011'], resolve),
    meta: {
      title: '卓越3分钟丨第十一集丨如何应对无理上司'
    }
  },
  {
    path: '/wei012',
    name: 'wei012',
    component: resolve => require(['@/views/weicourse/wei012'], resolve),
    meta: {
      title: '郭广文：行动学习助力数字化转型'
    }
  },
  {
    path: '/solution01',
    name: 'solution01',
    component: resolve => require(['@/views/solution/solution01'], resolve),
    meta: {
      title: '卓越解决方案-战略落地'
    }
  },
  {
    path: '/solution02',
    name: 'solution02',
    component: resolve => require(['@/views/solution/solution02'], resolve),
    meta: {
      title: '卓越解决方案-领导力提升'
    }
  },
  {
    path: '/solution03',
    name: 'solution03',
    component: resolve => require(['@/views/solution/solution03'], resolve),
    meta: {
      title: '卓越解决方案-行动学习'
    }
  },
  {
    path: '/solution04',
    name: 'solution04',
    component: resolve => require(['@/views/solution/solution04'], resolve),
    meta: {
      title: '卓越解决方案-海外业务拓展'
    }
  },
  {
    path: '/solution05',
    name: 'solution05',
    component: resolve => require(['@/views/solution/solution05'], resolve),
    meta: {
      title: '卓越解决方案-销售工作的变化'
    }
  },
  {
    path: '/solution06',
    name: 'solution06',
    component: resolve => require(['@/views/solution/solution06'], resolve),
    meta: {
      title: '卓越解决方案-培训系列'
    }
  },
  {
    path: '/ala',
    name: 'ala',
    component: resolve => require(['@/views/ala'], resolve),
    meta: {
      title: '4天线上行动学习催化师认证课'
    }
  },
  {
    path: '/coachPage',
    name: 'coachPage',
    component: resolve => require(['@/views/coachPage'], resolve),
    meta: {
      title: '教练引领式行动学习价值与选题'
    }
  },
  {
    path: '/coachPage01',
    name: 'coachPage01',
    component: resolve => require(['@/views/coachPage01'], resolve),
    meta: {
      title: '教练引领式行动学习项目介绍'
    }
  },
  {
    path: '/coachPage02',
    name: 'coachPage02',
    component: resolve => require(['@/views/coachPage02'], resolve),
    meta: {
      title: '教练引领式行动学习项目如何助力领导力提升？'
    }
  },
  {
    path: '/coachPage03',
    name: 'coachPage03',
    component: resolve => require(['@/views/coachPage03'], resolve),
    meta: {
      title: '高管如何突破“防御性归因”思维，促进组织学习？'
    }
  },
  {
    path: '/catalysts',
    name: 'catalysts',
    component: resolve => require(['@/views/catalysts'], resolve),
    meta: {
      title: '催化师认证'
    }
  },

  {
    name: '404',
    path: '/404',
    component: resolve => require(['@/views/notFound'], resolve),
  },
  {
    path: '*', // 此处需特别注意至于最底部
    redirect: '/404'
  }
  ]
})