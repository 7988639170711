// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import { megamenu } from './assets/touchvue.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import scrollReveal from 'scrollreveal'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css'
import 'videojs-contrib-hls'
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import VueRouter from 'vue-router'

Vue.use(VueRouter)

Vue.use(ElementUI)
Vue.prototype.$scroll = scrollReveal
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false

//跳页时从顶端开始
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0);
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (to.meta.requireAuth) {
        if (!getCookie('userName')) {
            if (to.name == 'login') {
                next()
            } else {
                next({ path: '/' })
            }
        } else {
            next()
        }
    } else {
        next()
    }
})
/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    components: { App },
    template: '<App/>'
})
