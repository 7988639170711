<template>
    <div class="footerQnav w-100 pb-3 pt-5 text-white bg-dark ">
        <div class="container ">
            <!--返回顶端-->
            <div class=" position-fixed bottom right z-index-100 " style=" width: 120px; height: 150px;">
                <img v-if="btnFlag" class="go-top w-75" src="img/gotop.svg" @click="backTop">
            </div>

            <div class="row justify-content-md-between pt-4 px-2">
                <div class="col-12 col-md-6 order-lg-3 mb-7 mb-lg-0 pb-5  text-center text-sm-right">

                    <!-- 列表组 -->
                    <div class="list-group list-group-flush list-group-transparent">
                        <h6>北京总部</h6>
                        <small class=" font-weight-light"> 北京海淀区知春路113号银网中心A座1801室</small>
                        <small class=" font-weight-light"> 18511937798</small>
                        <small class=" font-weight-light"> liuziqing@expjoy.com</small>
                        </br>
                        <h6>苏州分公司</h6>
                        <small class=" font-weight-light"> 苏州新区邓尉路9号润捷广场1号楼305A</small>
                        <small class=" font-weight-light"> 18112606402</small>
                        <small class=" font-weight-light"> liuaiyun@expjoy.com</small>
                    </div>
                    <!-- 列表组结束 -->
                </div>

                <div
                    class="col-12 col-md-2 order-lg-4 mb-7 mb-lg-0 pb-5 px-5 pt-sm-2 px-sm-0 pr-md-2 text-center text-sm-right">
                    <img class="w-75 " src="img/QR.jpeg" alt="">
                </div>


                <div
                    class="col-lg-4 order-lg-1 d-flex align-items-start flex-column text-center text-sm-left pt-0 pt-sm-2 ">
                    <!-- Logo -->
                    <div class="row mx-0">
                        <div class="col-6 px-0">
                            <a class="d-inline-block mb-5 m-auto m-sm-0 pb-3 w-100 px-2" href="index.html"
                                aria-label="Space">
                                <img src="img/logos/logow.svg" alt="Logo" class="w-75">
                            </a>
                        </div>
                        <div class="col-6 px-0">
                            <a class="d-inline-block mb-5 m-auto m-sm-0 pb-3 w-100 px-2"
                                href="http://www.actionlearningassociates.co.uk/" target="_blank" aria-label="Space">
                                <img v-if="btnFlag" class="go-top w-75" src="img/action.svg" @click="backTop">
                            </a>
                        </div>
                    </div>

                    <p class="text-muted w-100 m-0 h6">卓越体验管理顾问有限公司</p>
                    <!-- End Logo -->
                    <p class="small text-muted w-100 m-0">All rights reserved. &copy; Expjoy. 2007-2019 Htmlstream.</p>
                    <p class="small text-muted w-100">
                        <a href="http://www.beian.miit.gov.cn" target="_blank" class="text-muted ">京ICP备18008097号-1</a>
                    </p>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
// var cnzz_protocol = (("https:" == document.location.protocol) ? "https://" : "http://");document.write(unescape("%3Cdiv id='cnzz_stat_icon_1277460624'%3E%3C/p%3E%3Cscript src='" + cnzz_protocol + "s5.cnzz.com/z_stat.php%3Fid%3D1277460624%26online%3D1%26show%3Dline' type='text/javascript'%3E%3C/script%3E"));
export default {
    name: "footerQnav",
    data() {
        return {
            btnFlag: '',
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollToTop)
        // 创建cnzz统计js
        // const script = document.createElement('script')
        // script.src = `https://s5.cnzz.com/z_stat.php?id=${1277460624}&web_id=${1277460624}`
        // script.language = 'JavaScript'
        // document.body.appendChild(script)
    },
    destroyed() {
        window.removeEventListener('scroll', this.scrollToTop)
    },

    methods: {
        // 点击图片回到顶部方法，加计时器是为了过渡顺滑
        backTop() {
            let that = this
            let timer = setInterval(() => {
                let ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },

        // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
        scrollToTop() {
            let that = this
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            that.scrollTop = scrollTop
            if (that.scrollTop > 60) {
                that.btnFlag = true
            } else {
                that.btnFlag = false
            }
        }
    },

    // watch: {
    //     '$route': {
    //         handler(to, from) {
    //             setTimeout(() => { //避免首次获取不到window._czc
    //                 if (window._czc) {
    //                     let location = window.location;
    //                     let contentUrl = location.pathname + location.hash;
    //                     let refererUrl = '/';
    //                     // 用于发送某个URL的PV统计请求，
    //                     window._czc.push(['_trackPageview', contentUrl, refererUrl])
    //                 }
    //             }, 300)
    //         },
    //         immediate: true  // 首次进入页面即执行
    //     }
    // }
}
</script>

<style scoped></style>