<template>
  <div id="app" class="p-0 overflow-hidden">
    <div class="row">
      <div class="col-12 container">
        <HeaderNav></HeaderNav>
        <div class="min-h-55">
          <router-view />
        </div>
      </div>
      <div class="col-12 ">
        <FooterQnav></FooterQnav>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderNav from '@/components/headerNav.vue'
import FooterQnav from '@/components/footerQnav.vue'

export default {
  name: 'App',
  components: {
    HeaderNav,
    FooterQnav
  },
  created() {
    this.$nextTick(() => {
      // 禁用右键
      // document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  }
}
</script>
<style lang="scss"></style>
